import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { PwTranslateLoader } from '@pw-translate-loader';
import { BehaviorSubject, Observable } from 'rxjs';

export const PW_AVAILABLE_LANGUAGES = new InjectionToken<string[]>('PW_LOCALE_AVAILABLE_LANGUAGES');
export const PW_DEFAULT_LANGUAGES = new InjectionToken<string>('PW_LOCALE_DEFAULT_LANGUAGES');

@Injectable({ providedIn: 'root' })
export class PwLocaleService {
  private _availableLanguages: string[] = [];

  public get availableLanguages(): string[] {
    return [...this._availableLanguages];
  }

  public set availableLanguages(value: string[]) {
    this._availableLanguages = [...value];
  }

  private _defaultLanguage: string;

  public get defaultLanguage(): string {
    return this._defaultLanguage;
  }

  public set defaultLanguage(value: string) {
    this._defaultLanguage = value || this._getBrowserLanguage() || this.availableLanguages[0];
  }

  public get currentLanguage(): string {
    return this._language.value;
  }

  public get currentLanguageUpper(): string {
    return this._language.value.toUpperCase();
  }
  private _language: BehaviorSubject<string> = new BehaviorSubject<string>('en');
  private _localeKey = 'locale';

  constructor(
    private _translate: TranslateService,
    private _translateLoader: PwTranslateLoader,
    @Optional() private _dateAdapter: DateAdapter<any>,
    @Optional() @Inject(PW_DEFAULT_LANGUAGES) defaultLanguage: string,
    @Optional() @Inject(PW_AVAILABLE_LANGUAGES) availableLanguages: string[]
  ) {
    this.availableLanguages = availableLanguages;
    this.defaultLanguage = this._getBrowserLanguage();
  }

  public init(): void {
    // console.log('locale init');
    const lan = localStorage.getItem(this._localeKey) || this.defaultLanguage;
    // console.log(`lan: ${lan}`);
    // console.log(`this.defaultLanguage: ${this.defaultLanguage}`);
    // console.log(`this._translate.currentLang: ${this._translate.currentLang}`);
    this.setLocale(lan);

    this._language = new BehaviorSubject<string>(lan);
    // console.log(`this._language: ${this._language.value}`);

    this._translate.setDefaultLang(this.defaultLanguage);

    this.listenLocale().subscribe((lang) => {
      // console.log(`listenLocale: ${lang}`);
      this._translate.use(lang);
      this._dateAdapter?.setLocale(lang);
    });

    this._translateLoader.listenModuleChanges().subscribe((val) => {
      // console.log('listenModuleChanges');
      // console.log(val);
      this._translate.langs.forEach((lang) => this._translate.reloadLang(lang));
      this._translate.use(this._language.value);
      this.setLocale(this._language.value);
    });
  }

  public listenLocale(): Observable<string> {
    return this._language.asObservable();
  }

  public setLocale(lang: string): void {
    // console.log(`setLocale: ${lang}`);
    setTimeout(() => {
      this._language.next(lang);
      localStorage.setItem(this._localeKey, lang);
    }, 100);
  }

  private _getBrowserLanguage(): string {
    return window.navigator.languages.find((lang) => {
      const found = this.availableLanguages.includes(lang);
      // console.log(`lang; ${lang} -> ${found}`);
      return found;
    });
  }
}

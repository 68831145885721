import { Component } from '@angular/core';
import { AppWithSwComponent } from '@shared/core/components/app-with-sw/app-with-sw.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AppWithSwComponent {
  title = 'pickwings-consignee';
}
